import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { BreakPoint } from '@/components/styles/media';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import type { EventVerticalSection, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { EventHorizontal } from './EventHorizontal';
import { EventItem } from './EventItem';
import * as styles from './EventVertical.styles';

interface VerticalProps {
  section: SectionByLayout<SectionLayoutType.EventVertical>;
}

const Vertical = ({ section }: VerticalProps): ReactJSX.Element => (
  <SectionLayout title={section.title} link={section.more_details?.path} horizontal={false}>
    <ul css={styles.verticalWrapperStyle}>
      {section.items.map((item, index) => (
        <li css={styles.verticalItemWrapperStyle} key={item.id}>
          <EventItem item={item} index={index} />
        </li>
      ))}
    </ul>
  </SectionLayout>
);

export interface EventVerticalProps {
  section: EventVerticalSection;
}

export const EventVertical = ({ section }: EventVerticalProps): ReactJSX.Element => {
  const isBelowDesktopSmall = useResponsiveIsBelow(BreakPoint.DesktopSmall, false);

  return isBelowDesktopSmall ? <Vertical section={section} /> : <EventHorizontal section={section} />;
};
