import { css } from '@emotion/react';

export const verticalWrapperStyle = css`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const verticalItemWrapperStyle = css`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;
