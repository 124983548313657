import { css } from '@emotion/react';

import { sectionHeaderStyle } from '@/components/genreHome/common/SectionLayout/SectionLayout.styles';
import { sectionMarginStyle } from '@/components/genreHome/sections/index.styles';
import { pageContainerStyle } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';

export const sectionContainerStyle = css`
  ${sectionMarginStyle};

  padding-bottom: 30px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-bottom: 16px;
    `,
  )};
`;

export const selectionCarouselSectionHeaderStyle = css`
  ${sectionHeaderStyle};
  ${pageContainerStyle};
`;

export const selectionCarouselContentContainerStyle = css`
  ${pageContainerStyle};
  )}
`;
