import { useCallback, useMemo, useState } from 'react';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { ImageWithHandler } from '@/components/common/ImageWithHandler';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import type { EventSectionItem } from '@/models/backendsApi/v2/Views/ViewsType';

import * as styles from './EventItem.styles';

interface EventItemProps {
  item: EventSectionItem;
  index: number;
}

const renderCopyRights = (copyRights: string[]): ReactJSX.Element => (
  <div css={styles.copyRightWrapperStyle}>
    {copyRights.map(copyRight => (
      <p css={styles.copyRightStyle} key={copyRight}>
        {copyRight}
      </p>
    ))}
  </div>
);

export const EventItem = ({ item, index }: EventItemProps): ReactJSX.Element => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const sectionTrackingData = useSectionTrackingDataContext();
  const onLoad = useCallback(() => setImageLoaded(true), []);
  const trackingData = useMemo(
    () => ({
      ...sectionTrackingData.params,
      event_id: item.id.toString(),
      event_index: index,
      event_title: item.contents.title,
      event_landing_url: item.contents.landing_url,
    }),
    [item, index, sectionTrackingData.params],
  );

  return (
    <TrackClickEvent screenName={sectionTrackingData.screenName} target="event" params={trackingData}>
      <a
        css={[styles.itemWrapperStyle(item.contents.bg_color), !imageLoaded && styles.placeHolderStyle]}
        href={item.contents.landing_url}>
        <div css={styles.itemTitleTextWrapperStyle}>
          <p css={styles.titleStyle}>{item.contents.title}</p>
          {item.contents.copyrights &&
            item.contents.copyrights.length > 0 &&
            renderCopyRights(item.contents.copyrights)}
        </div>
        <div css={styles.itemImageContainerStyle}>
          <TrackViewEvent screenName={sectionTrackingData.screenName} target="event" params={trackingData}>
            <ImageWithHandler
              src={item.contents.main_image_url}
              alt={`${item.contents.title} 이벤트 이미지`}
              css={styles.itemImageStyle}
              draggable={false}
              onLoad={onLoad}
            />
          </TrackViewEvent>
        </div>
      </a>
    </TrackClickEvent>
  );
};
