import { css, SerializedStyles } from '@emotion/react';

import { lineClamp, ZIndexLayer } from '@/components/styles';
import { RIDITheme } from '@/components/styles/themes';

const IMAGE_WIDTH = 200;
const IMAGE_HEIGHT = 90;
const ITEM_LEFT_PADDING = 24;

export const itemWrapperStyle = (backgroundColor: string): SerializedStyles => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  background-color: ${backgroundColor};
  position: relative;
`;

export const placeHolderStyle = (theme: RIDITheme): SerializedStyles => css`
  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: ${IMAGE_HEIGHT}px;
    background: ${theme.colors.grey100};
    border-radius: 4px;
    position: absolute;
  }
`;

export const itemTitleTextWrapperStyle = css`
  width: 100%;
  padding-left: ${ITEM_LEFT_PADDING}px;
  position: absolute;

  left: 0;
  z-index: ${ZIndexLayer.LEVEL_BASE + 1};
`;

export const titleStyle = (theme: RIDITheme): SerializedStyles => css`
  max-width: 200px;
  color: ${theme.colors.white};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.01em;

  ${lineClamp(2, '20px')};
  white-space: pre-line;
  word-break: normal;
`;

export const copyRightWrapperStyle = css`
  max-width: calc((100% + ${ITEM_LEFT_PADDING}px) * 0.7);
  margin-top: 4px;
  opacity: 0.4;
`;

export const copyRightStyle = (theme: RIDITheme): SerializedStyles => css`
  font-size: 7px;
  letter-spacing: -0.01em;
  color: ${theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const itemImageContainerStyle = css`
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const itemImageStyle = css`
  max-width: 100%;
  max-height: 100%;

  border-radius: 4px;
`;
