import { useCallback } from 'react';

import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { ListLayoutWithColorBox } from '@/components/genreHome/common/ListLayoutWithColorBox';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import type { EventSectionItem, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { SectionHeadContent } from '../../common/SectionLayout';
import { useSectionTrackingDataContext } from '../../common/SectionTrackingDataContextProvider';
import * as styles from './EventHorizontal.styles';
import { EventItem } from './EventItem';

export interface EventHorizontalProps {
  section: SectionByLayout<SectionLayoutType.EventHorizontal | SectionLayoutType.EventVertical>;
}

export const EventHorizontal = ({ section }: EventHorizontalProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const renderItem = useCallback(
    ({ item, index }: { item: EventSectionItem; index: number }) => <EventItem item={item} index={index} />,
    [],
  );

  return (
    <TrackViewEvent screenName={sectionTrackingData.screenName} target="section" params={sectionTrackingData.params}>
      <section css={styles.sectionContainerStyle}>
        <header css={styles.selectionCarouselSectionHeaderStyle}>
          <SectionHeadContent title={section.title} link={section.more_details?.path} />
        </header>
        <div css={styles.selectionCarouselContentContainerStyle}>
          <ListLayoutWithColorBox items={section.items} renderItem={renderItem} />
        </div>
      </section>
    </TrackViewEvent>
  );
};
